<template>
  <div class="fb-add">
    <img
      class="ban"
      :src="require('../../assets/image/feedBack/feedback.png')"
      alt=""
    />
    <input
      type="text"
      class="iccid"
      v-model="iccid"
      placeholder="请输入ICCID/SIM卡号"
      oninput="this.value = this.value.slice(0,20)"
    />
    <button class="submit" :disabled="iccid.length < 4" @click="submit">
      查询
    </button>
  </div>
</template>

<script>
// 需要哪些模块按需引入
import { toRefs, reactive, onMounted, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { checkCard } from "_API_/api.services";
import { setStore } from "@/utils/store";

export default {
  setup() {
    const state = reactive({
      iccid: ""
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {
      submit() {
        // 查询卡号信息
        checkCard({
          card: state.iccid
        }).then(({ data: res }) => {
          if (res.code == 0) {
            store.dispatch("QueryOrder", res);
            router.push({
              name: "fb-list"
            });
            return;
          }
          tool.toast({
            msg: res.msg
          });
        });
      }
    };
    onMounted(() => {
      //获取路由携带的参数或者默认为空
      state.iccid = route.query.card || "";
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.ban {
  width: 100vw;
  height: 400px;
}
.fb-add {
  height: 100vh;
  background: #fff;
  overflow: hidden;
  text-align: center;

  .iccid,
  .submit {
    width: 94%;
    border: none;
    font-size: 30px;
  }

  .iccid {
    height: 100px;
    border-bottom: 1px solid #ccc;
    margin: 10px 0 30px;
  }

  .submit {
    height: 80px;
    color: #fff;
    @include background_color("background_color1");
    border-radius: 6px;
  }

  .submit[disabled] {
    @include background_color("background_color2");
  }
}
</style>
